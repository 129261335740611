import { DownloadSection, useFetchDownloadMapJson } from '@apeiron/download'
import { NextImage, noForwardProps, useAccountContext } from '@apeiron/temp'
import { GotoBattlePassEvent, GotoLootRush, GotoWebStore } from '@apeiron/ui'
import { Box, styled } from '@mui/material'
import StickyBackgroundJPG from '@public/images/background-download-2.png'
import AboutSection from '@src/components/battle/demo/download/AboutSection'
import BattleDemoSecNavBar from '@src/components/navigationBar/secondary/BattleDemoSecNavBar'
import ENV_CONFIG from '@src/config'
import { FeatureFlagType } from '@src/constants/featureFlag'
import { ROUTE } from '@src/constants/route'
import { useSecondaryNavBarContext } from '@src/contexts/share/SecondaryNavBarContext'
import featureAllowed from '@src/util/apeiron/featureAllowed'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import { useEffect, useMemo } from 'react'

const Container = styled(Box)`
  background-color: #12151c;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
`

const BackgroundContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-x: clip;
  position: relative;
  width: 100%;
`

const GotoBox = styled(Box, noForwardProps)<GotoProps>`
  display: flex;
  flex-direction: column;
  position: fixed;

  ${props => props.theme.breakpoints.up('res768')} {
    gap: 28px;
    right: 30px;
    top: calc(134px - ${props => (props.__noSubbar ? 56 : 0)}px);
    width: 230px;
  }
  ${props => props.theme.breakpoints.down('res768')} {
    gap: 18px;
    right: 12px;
    top: calc(124px - ${props => (props.__noSubbar ? 56 : 0)}px);
    width: 170px;
  }
`

const StickyBackground = styled(Box)`
  position: sticky;
  top: 0px;
  width: 100%;
`

const StickyImage = styled(NextImage)`
  aspect-ratio: 1918/395;
  background: linear-gradient(180deg, #000 0%, #00000000 50%);
  margin-bottom: calc(-100% * 395 / 1918 + 100px);
  width: 100%;
`

const GamePage: NextPage<Props> = props => {
  const { className, homeMode = false } = props

  const { query } = useRouter()

  const { promptLogin } = query

  const { isLoggedIn, setShowLoginPopup } = useAccountContext()

  const { setupBar } = useSecondaryNavBarContext()

  const { data } = useFetchDownloadMapJson()

  const infoList = useMemo(
    () => (R.isNotNil(data) ? data.officialSite : []),
    [data]
  )

  useEffect(() => {
    if (promptLogin && !isLoggedIn) {
      setShowLoginPopup(true)
    }
  }, [isLoggedIn, promptLogin, setShowLoginPopup])

  useEffect(() => {
    if (!homeMode) {
      setupBar(<BattleDemoSecNavBar route={ROUTE.BATTLE_DEMO_GAME} />)
    }

    return () => setupBar(null)
  }, [setupBar, homeMode])

  return (
    <Container className={className}>
      <BackgroundContainer>
        <DownloadSection infoList={infoList} showTitle={!homeMode} />
      </BackgroundContainer>
      <BackgroundContainer>
        <StickyBackground>
          <StickyImage src={StickyBackgroundJPG} />
        </StickyBackground>
        <AboutSection />
      </BackgroundContainer>
      <GotoBox __noSubbar={homeMode}>
        {featureAllowed(FeatureFlagType.ShowLootRush) && (
          <GotoLootRush
            url={ENV_CONFIG.EXTERNAL_URL.LOOTRUSH.APEIRON}
            target='_blank'
          />
        )}
        <GotoBattlePassEvent url={ENV_CONFIG.WEB_URL.STORE} target='_blank' />
        <GotoWebStore url={ENV_CONFIG.WEB_URL.STORE} target='_blank' />
      </GotoBox>
    </Container>
  )
}

type GotoProps = {
  __noSubbar: boolean
}

type Props = {
  className?: string
  homeMode?: boolean
}

export default GamePage
